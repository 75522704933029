import Vue from 'vue'
import { Spin,Icon,Page,Input } from 'iview';
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('Spin', Spin);
Vue.component('Icon', Icon);
import Header from '@/components/Header/header.vue'
import ProductType from '@/components/ProductType/productType.vue'
import ProductMenu from '@/components/ProductMenu/productMenu.vue'
import { mapState } from "vuex";
import { getShopSupService } from "@/service/spu-service";
import { getSupGroupService } from "@/service/spu-group-service"
import { dataUnit, timeUnit } from "@/utils/tools";
const OriginalList = {
    name:'OriginalList',
    components:{
        Header,
        ProductType,
        ProductMenu
    },
    computed: {
        ...mapState({
            userName: state => state.userCenter.userName,  //获取状态管理用户名称
        }),
    },
    data() {
        return {
            dataUnit:dataUnit,
            timeUnit:timeUnit,
            loadingVisible:false,
            originalList:{}, //商品列表对象
            originalSelect:[],//选中的商品列表id数组
            originalParams:{
                classifySelect:'', //选择分类
                cid1:1, //一级分类
                cid2:'',
                cid3:'',
                cid4:'',
                cid5:'',
                isSale:'', //折扣
                isNew:'', //新款
                isOld:'', //古着
                isStar:0, //星选
                isShow:0, //秀款
                isSole:0, //独家
                prices:[], //价格
                brandsSelect:'', //品牌
                attributeIds:'', //属性
                currentPage:1,
                pageSize:100
            },
        }
    },
    created() {
        this.onShopSupList().then()
    },
    methods: {

        /**
         * 选中商品
         * @param id
         */
        onOriginalSelect(id) {
            if(this.originalSelect.indexOf(id) === -1){
                this.originalSelect.push(id)
            }else{
                this.originalSelect.splice(this.originalSelect.indexOf(id),1)
            }
        },
        /**
         * 合并商品
         */
        onMerge() {
            if(this.originalSelect.length<1){
                alert('请选择1条商品进行合并！')
                return
            }
            this.onSupGroup().then()
        },
        /**
         * 选择一级分类
         */
        onSelectType(id){
            this.originalParams.cid1 = id
            this.onShopSupList().then()
        },
        /**
         * 价格
         * @param price
         */
        onPrice(price){
            this.loadingVisible = true
            this.originalParams.prices = price
            this.onShopSupList().then()
        },
        /**
         * 选择分类
         * @param obj
         */
        onSelectClassify(obj){
            if(obj.level === 2){
                this.originalParams.cid2 = obj.id
            }
            if(obj.level === 3){
                if(obj.isParent === 0){
                    let classifySelect = ''
                    for(let i in obj.list){
                        classifySelect += obj.list[i].id+','
                    }
                    this.originalParams.cid3 = classifySelect
                }else{
                    this.originalParams.cid3 = obj.id
                }
            }
            if(obj.level === 4){
                if(obj.isParent === 0){
                    let classifySelect = ''
                    for(let i in obj.list){
                        classifySelect += obj.list[i].id+','
                    }
                    this.originalParams.cid4 = classifySelect
                }else{
                    this.originalParams.cid4 = obj.id
                }
            }
            if(obj.level === 5){
                if(obj.isParent === 0){
                    let classifySelect = ''
                    for(let i in obj.list){
                        classifySelect += obj.list[i].id+','
                    }
                    this.originalParams.cid5 = classifySelect
                }else{
                    this.originalParams.cid5 = obj.id
                }
            }
            this.onShopSupList().then()
        },
        /**
         * 品牌筛选条件
         * @param list
         */
        onSelectBrands(list){
            this.originalParams.brandsSelect = ''
            for(let i in list){
                this.originalParams.brandsSelect += list[i].id+','
            }
            this.onShopSupList().then()
        },
        /**
         * 属性筛选条件
         * @param list
         */
        onSelectAttribute(list){
            this.originalParams.attributeIds = ''
            for(let i in list){
                this.originalParams.attributeIds += list[i].id+','
            }
            this.onShopSupList().then()
        },
        /**
         * 新款古着折扣条件筛选
         * @param obj
         */
        onSelectNewIn(obj){
            this.originalParams.isSale = obj.isSale
            this.originalParams.isOld = obj.isOld
            this.originalParams.isNew = obj.isNew
            this.onShopSupList().then()
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.originalParams.currentPage = number
            this.onShopSupList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.originalParams.pageSize = number
            this.onShopSupList().then()
        },
        /**
         * 拉取商品列表
         * @returns {Promise<void>}
         */
        async onShopSupList() {
            try {
                this.loadingVisible = true
                let params = {
                    'brandIds':this.originalParams.brandsSelect,
                    'attributeIds':this.originalParams.attributeIds,
                    'price2':this.originalParams.prices.length!==0?this.originalParams.prices[0]+','+this.originalParams.prices[1]:'',
                    'cid1':this.originalParams.cid1,
                    'cid2':this.originalParams.cid2,
                    'cid3':this.originalParams.cid3,
                    'cid4':this.originalParams.cid4,
                    'cid5':this.originalParams.cid5,
                    'isSale':this.originalParams.isSale,
                    'isNew':this.originalParams.isNew,
                    'isOld':this.originalParams.isOld,
                    'isStar':this.originalParams.isStar,
                    'isShow':this.originalParams.isShow,
                    'isSole':this.originalParams.isSole,
                    'size':this.originalParams.pageSize,
                    'page':this.originalParams.currentPage,
                    'status':0
                }
                const data = await getShopSupService(params)
                this.originalList = data
                this.loadingVisible = false
            }catch (error){
                this.loadingVisible = false
                console.log(error)
            }
        },
        /**
         * 合并商品
         * @returns {Promise<void>}
         */
        async onSupGroup() {
            try {
                let params = {
                    'ids':this.originalSelect,
                }
                const { code } = await getSupGroupService(params)
                if( code === 1){
                    this.onShopSupList().then()
                    alert('合并成功！')
                }else{
                    alert('合并失败！')
                }

            }catch (error){
                console.log(error)
            }
        },
    },
}
export default OriginalList
