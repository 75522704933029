<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <ProductType @on-select-type="onSelectType"/>
        <!--e: Type-->

        <!--s: Data-->
        <div class="product-prompt">
            <div class="product-data">{{ dataUnit() }}</div>
        </div>
        <!--e: Data-->

        <!--s: Body-->
        <div class="product-body">

            <!--s: Menu-->
            <div class="product-menu">

                <!--s: Menu Box-->
                <ProductMenu @on-price="onPrice" @on-select-type="onSelectClassify" @on-select-brands="onSelectBrands" @on-select-attribute="onSelectAttribute" @on-select-new-in="onSelectNewIn"/>
                <!--e: Menu Box-->

                <!--s: Handle-->
                <div class="product-handle">
                    <ul>
                        <li><a href="javascript:" @click="onMerge">Merge</a></li>
                        <li><a href="javascript:">Merge to</a></li>
                        <li><input type="text" class="handle-text"></li>
                    </ul>
                </div>
                <!--e: Handle-->

            </div>
            <!--e: Menu-->

            <!--s: List-->
            <div class="product-list">
                <Spin fix v-if="loadingVisible">
                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                    <div>Loading</div>
                </Spin>
                <ul>
                    <li class="list-li" :key="key" v-for="(originalList,key) in originalList.content" @click="onOriginalSelect(originalList.id)">
                        <div class="list-text">
                            <div class="list-img" :style="'background-image: url('+originalList.images+')'"></div>
                            <div class="list-box">
                                <span class="box-text bold">{{ originalList.name }}</span>
                                <span class="box-text">{{ originalList.description }}</span>
                                <span class="box-text bold">{{originalList.currency}}{{ originalList.price }}</span>
                            </div>
                        </div>
                        <span class="list-frame" :key="index" v-for="(select,index) in originalSelect" v-show="select === originalList.id"></span>
                    </li>
                </ul>

                <!--s: page-->
                <div class="product-page"><Page :total="originalList.totalElements" :current="originalParams.currentPage" :page-size="originalParams.pageSize"  @on-change="onSearchPage" @on-page-size-change="onSearchPageSize" size="small"></Page></div>
                <!--e: page-->

            </div>
            <!--e: List-->
        </div>
        <!--e: Body-->

    </div>
</template>
<script>
import OriginalList from './list'
export default OriginalList
</script>
<style lang="less">
@import "list";
</style>
